import * as React from 'react';

import { Layout } from 'src/components/Layout';
import { SEO } from 'src/components/SEO';
import { PageTitle } from 'src/components/PageTitle';
import { Reference } from 'src/components/Reference';
import { references } from 'src/content/references';
import ReferenceProps from 'src/interfaces/ReferenceProps';

const ReferencesPage = () => (
  <Layout>
    <SEO title="References" />
    <div className="container">
      <PageTitle title="References" />
      <div className="row justify-content-between">
        {
          references.map((reference: ReferenceProps, index) => (
            <div key={index}>
              <Reference
                name={reference.name}
                text={reference.text}
                title={reference.title}
                company={reference.company}
                logo={reference.logo}
                image={reference.image}
                quote={reference.quote}
              />
            </div>
          ))
        }
      </div>
    </div>
  </Layout>
);

export default ReferencesPage;
