import * as React from 'react';
import ReferenceProps from '../../interfaces/ReferenceProps';

import './Reference.scss';

export const Reference = ({
  text,
  name,
  company,
  image,
  logo,
  title,
  quote,
}: ReferenceProps) => (
  <div className="references__wrapper">
    <div className="references__innerwrapper">
      {
        image &&
                    <figure className="references__image">
                      <img src={image} alt={name} />
                    </figure>
      }
      <div>
        <div className="d-flex flex-column flex-md-row w-100">
          <div className="mr-3">
            <h2 className="mb-1">{name}</h2>
            <h2 className="mb-4 font-weight-light references__title">{title}</h2>
          </div>
        </div>
        <div className="d-flex">
          {
              image &&
              <figure className="references__image--mobile">
                <img src={image} alt={name} />
              </figure>
          }
        </div>
        <p className="mb-2">
          {text}
          {
            quote && <span>"{quote}"</span>
          }
        </p>
      </div>
    </div>
  </div>
);
